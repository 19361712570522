const STORAGE_TYPES = {
  local: "localStorage",
  cookies: "cookies",
};
import * as Cookie from "tiny-cookie";

export default class cookieHelper {
  supportsLocalStorage: boolean;
  storageName: string;
  storageType: string;
  cookieOptions: any;

  constructor() {
    this.storageName = "cookies:consent";
    this.cookieOptions = () => {};
    this.supportsLocalStorage = true;
    this.storageType = STORAGE_TYPES.local;
    if (this.storageType === STORAGE_TYPES.local) {
      // Check for availability of localStorage
      try {
        const test = "__vue-cookielaw-check-localStorage";
        if (typeof window !== "undefined") {
          window.localStorage.setItem(test, test);
          window.localStorage.removeItem(test);
        }
      } catch (e) {
        // console.info(
        //   "Local storage is not supported, falling back to cookie use",
        // );
        this.supportsLocalStorage = false;
      }
    }
  }

  canUseLocalStorage() {
    return this.storageType === STORAGE_TYPES.local && this.supportsLocalStorage;
  }

  setVisited(cookiesDetails: any) {
    if (this.canUseLocalStorage()) {
      if (cookiesDetails) {
        localStorage.setItem(this.storageName, cookiesDetails);
      } else {
        localStorage.setItem(this.storageName, "true");
      }
    } else {
      if (cookiesDetails) {
        Cookie.set(this.storageName, cookiesDetails, {
          ...this.cookieOptions,
          expires: "1Y",
        });
      } else {
        Cookie.set(this.storageName, true, {
          ...this.cookieOptions,
          expires: "1Y",
        });
      }
    }
  }

  setAccepted(cookiesDetails: any) {
    if (this.canUseLocalStorage()) {
      if (cookiesDetails) {
        localStorage.setItem(this.storageName, cookiesDetails);
      } else {
        localStorage.setItem(this.storageName, "true");
      }
    } else {
      if (cookiesDetails) {
        Cookie.set(this.storageName, cookiesDetails, {
          ...this.cookieOptions,
          expires: "1Y",
        });
      } else {
        Cookie.set(this.storageName, true, {
          ...this.cookieOptions,
          expires: "1Y",
        });
      }
    }
  }

  setDeclined() {
    if (this.canUseLocalStorage()) {
      localStorage.setItem(this.storageName, "false");
    } else {
      Cookie.set(this.storageName, false, {
        ...this.cookieOptions,
        expires: "1Y",
      });
    }
  }

  getVisited() {
    let visited: any = false;
    if (this.canUseLocalStorage()) {
      visited = localStorage.getItem(this.storageName);
    } else {
      visited = Cookie.get(this.storageName);
    }

    if (typeof visited === "string") {
      visited = JSON.parse(visited);
    }
    return !(visited === null || visited === undefined);
  }

  isAccepted() {
    let accepted: any = false;
    if (this.canUseLocalStorage()) {
      accepted = localStorage.getItem(this.storageName);
    } else {
      accepted = Cookie.get(this.storageName);
    }

    if (typeof accepted === "string") {
      accepted = JSON.parse(accepted);
    }

    return accepted;
  }

  isAnalyticsAccepted() {
    let accepted: any = false;
    if (this.canUseLocalStorage()) {
      accepted = localStorage.getItem(this.storageName);
    } else {
      accepted = Cookie.get(this.storageName);
    }

    if (typeof accepted === "string") {
      accepted = JSON.parse(accepted);
    }

    return accepted?.analytics;
  }
}
