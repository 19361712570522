











import Vue from "vue";
import {displayBirthdDate} from "@/tscript/utils/index";

export default Vue.extend({
  name: "DatePicker",
  props: {
    standardFormat: {
      type: Boolean,
      required: false,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      date: "",
      menu: false,
      dateFormatted: "",
      max: new Date().toISOString().substr(0, 10),
      min: "1940-01-01",
    };
  },
  watch: {
    context(val) {
      this.actualizeDateFormatted();
    },
    menu(val) {
      val && setTimeout(() => ((this.$refs.picker as any).activePicker = this.standardFormat ? "DATE" : "YEAR"));
    },
  },
  methods: {
    save(date: any) {
      this.dateFormatted = this.displayBirthdDate(date);

      const refMenu: any = this.$refs.menu;
      refMenu?.save(date);
      // eslint-disable-next-line vue/no-mutating-props
      this.context.model = date;
    },
    actualizeDateFormatted() {
      (this.date as any) = this.context.model;
      this.dateFormatted = this.displayBirthdDate(this.date);
    },
    displayBirthdDate(date: any) {
      return displayBirthdDate(date, this.$i18n.locale);
    },
  },
  created() {
    if (this.standardFormat) {
      this.max = "";
      this.min = new Date().toISOString().substr(0, 10);
    }
    this.actualizeDateFormatted();
  },
});
