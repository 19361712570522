import fb from "firebase/app";
import {user} from "@/store/user.module";

const state = {};

const actions = {
  async logAction({commit}: {commit: Function}, payload: any) {
    if (payload?.event_name) {
      const userData: any = user?.state?.userData;
      let params = {
        user: userData?.id ? userData?.id : "anonymous",
        user_first_name: userData?.first_name,
        user_last_name: userData?.last_name,
        client_name: userData?.client_name,
        sites_name: userData?.sites_name,
      };
      if (payload?.params) {
        params = {...params, ...payload?.params};
      }
      fb.analytics().logEvent(payload?.event_name, params);
    }
  },
};

const mutations = {};

export const analytics = {
  namespaced: true,
  state,
  actions,
  mutations,
};
