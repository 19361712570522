export const IS_STAGING = true;
export const IS_PROD = false;
export const IS_DEMO = false;
export const ZONE = "europe-west1";
export const DOMAIN_URL = "http://localhost:8080";

//export const BACKEND_URL = "http://localhost:8082/";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBJkAHGohDArze54PhZTB4mz5GVJnNM5kE",
  authDomain: "myc-telemedicine.firebaseapp.com",
  projectId: "myc-telemedicine",
  storageBucket: "myc-telemedicine.appspot.com",
  messagingSenderId: "487794091093",
  appId: "1:487794091093:web:8a6ae8bb03a05d4ecfa0dc",
};

//Staging
// export const BACKEND_URL = "https://covid-at-home-staging.appspot.com/";
// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyCWEoUbIl_uD9Tf-Y-BL7UA7MeaI7KyItc",
//   authDomain: "myc-dev.firebaseapp.com",
//   projectId: "myc-dev",
//   storageBucket: "myc-dev.appspot.com",
//   messagingSenderId: "62784605836",
//   appId: "1:62784605836:web:61f5456a04e5ff174d35a9",
//   measurementId: "G-BNTW12VE90",
//   databaseURL: "https://myc-dev.firebaseio.com",
// };

//Staging
// export const BACKEND_URL = "https://covid-at-home-staging.appspot.com/";
// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyA4OVRciuttk3v4IhuXmNj0iJH4J-Gg-e8",
//   authDomain: "covid-at-home-staging.firebaseapp.com",
//   databaseURL: "https://covid-at-home-staging.firebaseio.com",
//   projectId: "covid-at-home-staging",
//   storageBucket: "covid-at-home-staging.appspot.com",
//   messagingSenderId: "923335730864",
//   appId: "1:923335730864:web:80d6792a9a07d745c73d75",
//   measurementId: "G-5DVH8N6C4F",
// };

//PROD;
// export const BACKEND_URL = "https://api-london.myc.doctor";
//PROD EU;
// export const IS_STAGING = false;
// export const IS_PROD = true;
// export const IS_DEMO = false;
// export const DOMAIN_URL = "https://app-eu.myc.doctor";
// export const ZONE = "europe-west1";
// export const BACKEND_URL = "https://api-eu.myc.doctor";

//Staging
// export const BACKEND_URL = "https://covid-at-home-staging.appspot.com/";
// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyA4OVRciuttk3v4IhuXmNj0iJH4J-Gg-e8",
//   authDomain: "covid-at-home-staging.firebaseapp.com",
//   databaseURL: "https://covid-at-home-staging.firebaseio.com",
//   projectId: "covid-at-home-staging",
//   storageBucket: "covid-at-home-staging.appspot.com",
//   messagingSenderId: "923335730864",
//   appId: "1:923335730864:web:80d6792a9a07d745c73d75",
//   measurementId: "G-5DVH8N6C4F",
// };

//PROD;
// export const BACKEND_URL = "https://api-london.myc.doctor";
// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyAaqeLvUd7DBtzt3rXHujsGhPJPbnZfInA",
//   authDomain: "covid-at-home-prod.firebaseapp.com",
//   databaseURL: "https://covid-at-home-prod.firebaseio.com",
//   projectId: "covid-at-home-prod",
//   storageBucket: "covid-at-home-prod.appspot.com",
//   messagingSenderId: "1089110785084",
//   appId: "1:1089110785084:web:38694a6ac747b9eda75739",
//   measurementId: "G-KP2JWYDTT6",
// };

// DEMO

// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyARWbju1tbt62NCj0hvj2x8-1tXc6YOx50",
//   authDomain: "covid-myc-demo-london.firebaseapp.com",
//   databaseURL: "https://covid-myc-demo-london.firebaseio.com",
//   projectId: "covid-myc-demo-london",
//   storageBucket: "covid-myc-demo-london.appspot.com",
//   messagingSenderId: "1022903343840",
//   appId: "1:1022903343840:web:005111efb58c6ce1430e85",
//   measurementId: "G-302LF21TBK",
// };
