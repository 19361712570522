import Vue from "vue";
import Vuex from "vuex";
import fb from "firebase/app";
import db from "@/firebase/db";
import DbHelper from "@/tscript/dbHelper";
import * as _ from "lodash";
//import APIClient from "@/api/index";
import { user } from "./user.module";
import { snackbar } from "./snackbar.module";
import { initialRoute } from "./route.module";
import { network } from "./network.module";
import { countries } from "./countries.module";
import { analytics } from "./analytics.module";

const dbHelper = new DbHelper(db);
const refreshTime = 60000 * 10;

Vue.use(Vuex);
//const apiClient = new APIClient(fb);

export default new Vuex.Store({
  state: {
    userId: null,
    status: null,
    error: null,
    patientStatusColor: "",
    patientsList: null,
    usersList: null,
    //apiClient: new APIClient(fb),
    locale: "",
    version: "",
    home: null,
    appLoading: true,
    appLoadingValue: 0,
  },
  mutations: {
    setAppLoading(state, payload) {
      state.appLoading = payload;
    },
    setAppLoadingValue(state, payload) {
      state.appLoadingValue = payload;
    },
    incrementAppValue(state, payload) {
      const increment = payload ? payload : 10;
      state.appLoadingValue = state.appLoadingValue + increment;
    },
    setUserId(state, payload) {
      state.userId = payload;
    },
    setHome(state, payload) {
      state.home = payload;
    },
    setStatus(state, payload) {
      state.status = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setPatientStatusColor(state, payload) {
      state.patientStatusColor = payload;
    },
    setUsersList(state, payload) {
      state.usersList = payload;
    },
    setPatientsList(state, payload) {
      state.patientsList = payload;
    },
    // setApiClient(state, payload) {
    //   state.apiClient = payload;
    // },
    setLocale(state, payload) {
      state.locale = payload;
    },
    setVersion(state, payload) {
      state.version = payload;
    },
  },
  getters: {
    appLoadingValue(state) {
      return state.appLoadingValue;
    },
    appLoading(state) {
      return state.appLoading;
    },
    status(state) {
      return state.status;
    },

    home(state) {
      return state.home;
    },
    userId(state) {
      return state.userId;
    },

    error(state) {
      return state.error;
    },
    patientStatusColor(state) {
      return state.patientStatusColor;
    },
    patientsList(state) {
      return state.patientsList;
    },
    // apiClient(state) {
    //   return state.apiClient;
    // },
    locale(state) {
      return state.locale;
    },
    version(state) {
      return state.version;
    },
  },

  actions: {
    logInAction({ commit }, payload) {},
    logOutAction({ commit }) {
      commit("setUserId", null);
      commit("user/setUserData", null);
      fb.auth()
        .signOut()
        .then((response) => {
          commit("setStatus", "success");
          commit("setError", null);
        })
        .catch((error) => {
          commit("setStatus", "failure");
          commit("setError", error.message);
        });
    },
    async patientsListAction({ commit }, payload) {
      this.dispatch("analytics/logAction", { event_name: "get_patients_list" });
      commit("incrementAppValue", 5);
      const patientsList = await dbHelper.getAllDataFromCollection(
        "patients_admin"
      );
      let i = 0;
      for (const ip of patientsList) {
        ip.name = ip?.last_name?.toUpperCase() + " " + ip.first_name;
        ip["short_id"] = ip?.id?.substring(0, 5);
        patientsList[i] = ip;
        i++;
      }
      commit("incrementAppValue", 10);
      commit("setPatientsList", patientsList);
      setTimeout(() => {
        this.dispatch("patientsListAction");
      }, refreshTime);
    },
    async usersListAction({ commit }, payload) {
      this.dispatch("analytics/logAction", { event_name: "get_user_list" });
      let usersList = await dbHelper.getAllDataFromCollection("users");
      const userD: any = this.getters["user/userData"];
      if (userD) {
        usersList = _.filter(usersList, (user) => user.id != userD?.id);
      }
      commit("setUsersList", usersList);
    },
  },

  modules: {
    user,
    snackbar,
    initialRoute,
    network,
    countries,
    analytics,
  },
});
