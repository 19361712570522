import localForage from "localforage";
import { user } from "@/store/user.module";

const state = {
  networkStatus: true,
};

const actions = {
  async setNetworkStatusAction(
    { commit, dispatch }: { commit: Function; dispatch: Function },
    payload: any
  ) {
    if (payload) {
      // if online
      const offlineLogsData: any = await localForage.getItem("offline-logs");
      if (offlineLogsData?.client_id) {
        dispatch(
          "analytics/logAction",
          { event_name: "error_offline" },
          { root: true }
        );
        localForage.setItem("offline-logs", null);
      }
    } else {
      // if offline
      const userData: any = user?.state?.userData;
      const errorData: any = {
        client_id: userData?.client_id ? userData?.client_id : "anonymous",
        uid: userData?.id ? userData?.id : "anonymous",
        first_name: userData?.first_name ? userData?.first_name : "anonymous",
        last_name: userData?.last_name ? userData?.last_name : "anonymous",
        type: "offline",
        time: new Date(),
      };
      localForage.setItem("offline-logs", errorData);
    }
    commit("setNetworkStatus", payload);
  },
};

const mutations = {
  setNetworkStatus(state: any, payload: any) {
    state.networkStatus = payload;
  },
};

export const network = {
  namespaced: true,
  state,
  actions,
  mutations,
};
