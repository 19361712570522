const state = {
  initialRoute: "/",
};

const actions = {
  setInitialRouteAction({ commit }: { commit: Function }, payload: any) {
    commit("setInitialRoute", payload);
  },
};

const mutations = {
  setInitialRoute(state: any, payload: any) {
    state.initialRoute = payload;
  },
};

export const initialRoute = {
  namespaced: true,
  state,
  actions,
  mutations,
};
