









import Vue from "vue";
import {stringToColor} from "@/tscript/utils";

export default Vue.extend({
  name: "AvatarPicture",
  props: ["firstName", "lastName", "pictureUrl", "size"],
  data() {
    return {
      sizePicture: 70,
      classInitial: "headline",
    };
  },
  methods: {
    loadData() {
      if (this.size) {
        this.sizePicture = this.size;
        if (this.size < 50) {
          this.classInitial = "";
        }
      }
    },
  },
  computed: {
    color: function () {
      const computedColor = stringToColor(this.firstName + this.lastName);
      return computedColor;
    },
    initials: function () {
      let computedInitials = "";
      if (this.lastName && this.lastName.length > 0 && this.firstName && this.firstName.length > 0) {
        computedInitials = this.firstName.charAt(0) + this.lastName.charAt(0);
      }

      return computedInitials;
    },
  },
  created() {
    this.loadData();
  },
});
