import router from "@/router";
import db from "@/firebase/db";
import * as _ from "lodash";
import DbHelper from "@/tscript/dbHelper";

const dbHelper = new DbHelper(db);
const getFlag = (countryCode: string) => {
  let flag = "";
  const flags: any = {
    AO: "🇦🇴",
    BR: "🇧🇷",
    FR: "🇫🇷",
    SN: "🇸🇳",
    MC: "🇲🇨",
    HN: "🇭🇳",
  };
  if (flags[countryCode]) {
    flag = flags[countryCode];
  }
  return flag;
};
const state = {
  userData: null,
};

const actions = {
  setUserDataAction({ commit }: { commit: Function }, payload: any) {
    commit("setUserData", payload);
  },
  reloadUserAction(
    {
      commit,
      dispatch,
      getters,
      rootGetters,
    }: {
      commit: Function;
      dispatch: Function;
      getters: Function;
      rootGetters: any;
    },
    payload: any
  ) {
    if (payload && payload.uid) {
      commit("setUserId", payload.uid, { root: true });
      dispatch("loadUserDataAction", payload.uid);
    }
  },
  async loadUserDataAction(
    { commit, dispatch }: { commit: Function; dispatch: Function },
    payload: any
  ) {
    let user: any = null;
    const actionPromises = new Array();
    if (payload) {
      commit("setAppLoading", true, { root: true });
      commit("setAppLoadingValue", 0, { root: true });
      user = await dbHelper.getDocFromCollection("users", payload);
      if (user) {
        const where: any = { user_id: user.id };
        const network: any = await dbHelper.getDocFromCollectionWithWhere(
          "network",
          where
        );
        user.network = network;
        dispatch(
          "analytics/logAction",
          { event_name: "user_info_loaded" },
          { root: true }
        );
        if (user?.country) {
          user["flag"] = getFlag(user?.country);
        }

        commit("setUserData", user); //mon userResult est complet, je le mets dans le store

        commit("incrementAppValue", 10, { root: true });

        actionPromises.push(dispatch("usersListAction", null, { root: true }));
        actionPromises.push(
          dispatch("patientsListAction", null, { root: true })
        );
        await Promise.all(actionPromises);
        commit("incrementAppValue", 10, { root: true });
        const home = "home-contactbook";
        commit("setHome", home, { root: true });
        if (window.location.pathname == "/login") {
          router.push({ name: home });
        }
      } else {
        dispatch(
          "analytics/logAction",
          { event_name: "user_info_loading_error" },
          { root: true }
        );
        commit("setUserData", null, { root: true });
        commit("setStatus", "failure", { root: true });
        commit("setError", "could not load the user data", { root: true });
      }
    } else {
      actionPromises.push(dispatch("usersListAction", null, { root: true }));
      // A ENLEVER QUAND IL Y AURA LE LOGIN
      // actionPromises.push(dispatch("patientsListAction", null, { root: true }));
      await Promise.all(actionPromises);
    }
    commit("setAppLoading", false, { root: true });
  },
};

const mutations = {
  setUserData(state: any, payload: any) {
    state.userData = payload;
  },
  removeUser(state: any) {
    state.userData = null;
  },
};

const getters = {
  userData(state: any) {
    return state.userData;
  },
};

export const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
