import router from "@/router";
import db from "@/firebase/db";
import fb from "firebase/app";
import * as _ from "lodash";
import DbHelper from "@/tscript/dbHelper";
import axios from "axios";

const dbHelper = new DbHelper(db);

const state = {
  countriesList: null,
};

const actions = {
  setCountriesListAction({ commit }: { commit: Function }, payload: any) {
    commit("setCountriesList", payload);
  },

  async loadCountriesList({ commit }: { commit: Function }, payload: any) {
    const formatVueFormulate = payload;
    const resultAxios: any = await axios.get(
      "https://raw.githubusercontent.com/mledoze/countries/master/dist/countries.json"
    );
    const countryListRaw: any = resultAxios?.data;
    let countriesList = new Array();
    const countryCode = navigator.language?.substring(0, 2);
    if (countryListRaw) {
      if (countryCode === "pt") {
        if (!formatVueFormulate) {
          countriesList = countryListRaw.map((v: any) => ({
            ...v,
            id: v.cca2,
            name: v.translations.por.common,
          }));
        } else {
          countriesList = countryListRaw.map((v: any) => ({
            ...v,
            value: v.cca2,
            label: v.translations.por.common,
          }));
        }
      }
      if (countryCode === "en") {
        if (!formatVueFormulate) {
          countriesList = countryListRaw.map((v: any) => ({
            ...v,
            id: v.cca2,
            name: v.name.common,
          }));
        } else {
          countriesList = countryListRaw.map((v: any) => ({
            ...v,
            value: v.cca2,
            label: v.name.common,
          }));
        }
      }
      if (countryCode === "fr") {
        if (!formatVueFormulate) {
          countriesList = countryListRaw.map((v: any) => ({
            ...v,
            id: v.cca2,
            name: v.translations.fra.common,
          }));
        } else {
          countriesList = countryListRaw.map((v: any) => ({
            ...v,
            value: v.cca2,
            label: v.translations.fra.common,
          }));
        }
      }
      if (countryCode === "es") {
        if (!formatVueFormulate) {
          countriesList = countryListRaw.map((v: any) => ({
            ...v,
            id: v.cca2,
            name: v.translations.spa.common,
          }));
        } else {
          countriesList = countryListRaw.map((v: any) => ({
            ...v,
            value: v.cca2,
            label: v.translations.spa.common,
          }));
        }
      }
      if (countryCode === "ms") {
        if (!formatVueFormulate) {
          countriesList = countryListRaw.map((v: any) => ({
            ...v,
            id: v.cca2,
            name: v.translations.msa.common,
          }));
        } else {
          countriesList = countryListRaw.map((v: any) => ({
            ...v,
            value: v.cca2,
            label: v.translations.msa.common,
          }));
        }
      }
    }
    countriesList = _.orderBy(countriesList, ["name"], ["asc"]);
    commit("setCountriesList", countriesList);
  },
};

const mutations = {
  setCountriesList(state: any, payload: any) {
    state.countriesList = payload;
  },
  removeCountriesList(state: any) {
    state.countriesList = null;
  },
};

const getters = {
  countriesList(state: any) {
    return state.countriesList;
  },
};

export const countries = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
