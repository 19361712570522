
























































































































































































































import Vue from "vue";
import Navbar from "@/components/Navbar.vue";
import CookieLaw from "@/components/CookieLaw.vue";
import fb from "firebase/app";
import { VERSION } from "@/config/version";
import CookieHelper from "@/tscript/cookieHelper";
import { mapActions, mapState } from "vuex";
import MycSnackbar from "@/components/global/notifications/MycSnackbar.vue";

export default Vue.extend({
  components: {
    Navbar,
    CookieLaw,
    MycSnackbar,
  },
  name: "App",
  data() {
    return {
      online: true,
      appLoading: false,
      appLoadingValue: 0,
      displayNav: false,
      isMobile: false,
      version: VERSION,
      year: "",
      serverVersion: "",
      cookiesDialog: false,
      classNav: "",
      buttonBurger: "",
      windowHeight: "",
      analyticsCookies: {
        switch: false,
        list: ["ga"],
      },
      cookieHelper: new CookieHelper(),
      /*supportCookies: {
        switch: false,
        list: ["crisp-client"],
      },*/

      cookiesProps: null,
      privacyNotice: "/doc/privacy_notice.pdf",
    };
  },
  methods: {
    ...mapActions("network", [
      "setNetworkStatusAction",
      "setUserDataAction",
      "reloadUserAction",
    ]),
    ...mapActions("user", ["setUserDataAction", "reloadUserAction"]),
    ...mapActions("snackbar", ["setSnackbarAction"]),
    ...mapActions("countries", ["loadCountriesList"]),
    ...mapActions("analytics", ["logAction"]),
    windowSetHeight() {
      this.windowHeight = "height: " + window.innerHeight + "px";
    },
    goHome() {
      this.$router.push({ name: this.$store.getters.home });
    },
    showNav() {
      if (window.innerWidth < 950) {
        if (this.classNav === "nav-show-mobile") {
          this.buttonBurger = "";
          this.classNav = "";
        } else {
          this.buttonBurger = "burger-cross-mobile";
          this.classNav = "nav-show-mobile";
        }
      }
    },
    goToAbout() {
      this.$router.push({
        name: "about",
      });
    },
    includesArray(valueToCheck: string, valuesToContain: Array<string>) {
      for (const value of valuesToContain) {
        if (valueToCheck.includes(value)) return true;
      }
      return false;
    },
    deleteCookie(cookiename: string) {
      const host = window.location.host;
      const domain = host.includes("myc.doctor") ? "; domain=.myc.doctor" : "";
      const d = new Date();
      d.setDate(d.getDate() - 1);
      const expires = ";expires=" + d;
      const name = cookiename;
      const value = "";
      document.cookie = name + "=" + value + expires + "; path=/" + domain;
    },
    clearListCookies(reload = false) {
      let cookies = document.cookie.split(";");
      //const allCookies = [this.analyticsCookies, this.supportCookies];
      const allCookies = [this.analyticsCookies];
      let cookiesToDisable = new Array();
      let hasCookiesDeleted = false;
      allCookies.forEach((element) => {
        if (!element.switch) {
          cookiesToDisable = cookiesToDisable.concat(element.list);
        }
      });
      for (var i = 0; i < cookies.length; i++) {
        var spcook = cookies[i].split("=");
        if (this.includesArray(spcook[0], cookiesToDisable)) {
          hasCookiesDeleted = true;
          this.deleteCookie(spcook[0]);
        }
      }
      if (hasCookiesDeleted) {
        if (reload) {
          window.location.href = ""; // TO REFRESH THE PAGE
        }
      }
    },
    hotReload() {
      const location: string = window.location.href.toString();

      if (!location.includes("cache=")) {
        const d = new Date().getTime();
        if (location.includes("?")) {
          window.location.href = window.location + "&cache=" + d?.toString();
        } else {
          window.location.href = window.location + "?cache=" + d?.toString();
        }
      }
    },
    openCookiesDialog(props: any) {
      this.cookiesProps = props;
      this.cookiesDialog = true;
    },
    acceptAllCookies(props: any) {
      this.cookiesProps = props;
      this.analyticsCookies.switch = true;
      //this.supportCookies.switch = true;
      this.confirmPreferences();
    },
    confirmPreferences() {
      if (this.analyticsCookies.switch) {
        fb.analytics().setAnalyticsCollectionEnabled(true);
      } else {
        this.clearListCookies(true);
      }

      const cookiesProps: any = this.cookiesProps;
      cookiesProps?.accept({
        analytics: this.analyticsCookies.switch,
        //support: this.supportCookies.switch,
      });
      if (this.cookiesDialog) {
        this.cookiesDialog = false;
      }
    },
    versionToNumber(version: string) {
      let pos = version.lastIndexOf(".");
      version = version.substring(0, pos) + version.substring(pos + 1);

      return parseFloat(version);
    },
    onResize() {
      this.isMobile = window.innerWidth <= 950;
      this.windowHeight = "height: " + window.innerHeight + "px";
    },
    iAmOnline(online: boolean) {
      this.setNetworkStatusAction(online);
    },

    hasChangedPassword() {
      return (this.userData as any)?.has_changed_password;
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    ...mapState("snackbar", ["snackbar"]),
    ...mapState("network", ["networkStatus"]),

    selectAllSwitch: {
      get: function () {
        const vm: any = this;
        return vm.analyticsCookies.switch; // && vm.supportCookies.switch;
      },
      set: function (newValue: boolean) {
        const vm: any = this;
        vm.analyticsCookies.switch = newValue;
        //vm.supportCookies.switch = newValue;
      },
    },
  },
  async created() {
    if (!this.cookieHelper.isAnalyticsAccepted()) {
      fb.analytics().setAnalyticsCollectionEnabled(false);
      this.clearListCookies();
    } else {
      this.analyticsCookies.switch = true;
    }

    this.logAction({ event_name: "app_started" });
    this.loadCountriesList(true);
    this.$store.dispatch("usersListAction", null, { root: true });
    this.year = new Date().getFullYear().toString();
    document.title = "Agora - " + VERSION;
    // Set language to navigator language
    if (navigator.language && navigator.language.length >= 2) {
      const locale_iso = navigator.language.substring(0, 2);
      this.$i18n.locale = locale_iso;
      this.$store.commit("setLocale", locale_iso);
    }

    // Reload user if alredy logged
    const user = fb.auth().currentUser;
    const router: any = this.$router;
    const currentPage = router?.history.current.name;
    const currentPath = window.location.pathname;

    if (!user) {
      this.appLoading = false;
      if (currentPath == "/account/new") {
        this.$router
          .push({
            name: "account",
            params: { id: "new" },
          })
          .catch(() => {});
      } else if (currentPage != "/login") {
        const url = new URL(window.location.href);
        const cache = url.searchParams.get("cache");
        let query: any = {};
        if (cache) {
          query = { cache: cache };
        }

        this.$router.push({ name: "home-contactbook", query: query });
        this.displayNav = true;
      }
    } else {
      if (currentPage == null && currentPath == "/") {
        this.$router.push({ name: "login" });
      }
      user.getIdTokenResult().then((idTokenResult) => {
        const now = new Date().getTime();
        const authTime = idTokenResult.claims.auth_time * 1000;
        const hours = Math.abs(now - authTime) / 36e5;

        if (
          currentPath == "/sign-up" ||
          currentPath == "/re-schedule" ||
          currentPath == "/recurring-booking" ||
          currentPath == "/re-schedule-recurring"
        ) {
          this.displayNav = false;
        } else {
          if (hours < 2) {
            this.reloadUserAction(user);
            this.displayNav = true;
          } else {
            this.$store.dispatch("logOutAction").then(() => {
              this.$router.push({ name: "login" });
            });
          }
        }
      });
    }

    // Hide bar when logout
    this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "setAppLoadingValue" ||
        mutation.type === "incrementAppValue"
      ) {
        this.appLoadingValue = state.appLoadingValue;
      }
      if (mutation.type === "setAppLoading") {
        this.appLoading = state.appLoading;
      }

      if (mutation.type === "setUserId" && this.hasChangedPassword()) {
        if (state.userId) {
          this.displayNav = true;
        } else {
          this.displayNav = false;
        }
      }
      if (mutation.type === "user/setUserData") {
        //this.userData = state.user.userData;
        this.privacyNotice = state.user.userData?.privacy_notice
          ? state.user.userData.privacy_notice
          : this.privacyNotice;
        if (this.userData) {
          this.displayNav = true;
        } else {
          this.displayNav = false;
        }
        // if (state.userData?.client_logo_url) {
        //   this.displayNav = true;
        // } else {
        //   this.displayNav = false;
        // }
      }

      if (mutation.type === "setCampaignData") {
        //PERSONALIZE_SIGN_UP
        if (state.campaignData?.personalizeSignUp?.privacyNoticeLink) {
          this.privacyNotice =
            state.campaignData?.personalizeSignUp?.privacyNoticeLink;
        } else if (state.campaignData?.privacy_notice_link) {
          this.privacyNotice = state.campaignData?.privacy_notice_link;
        }
        //const privacyNotice = state.campaignData?.privacy_notice_link;
        //PERSONALIZE_SIGN_UP
      }

      if (mutation.type === "setVersion") {
        if (state.version) {
          this.serverVersion = state.version;
          if (
            this.version &&
            this.serverVersion &&
            this.versionToNumber(this.version) <
              this.versionToNumber(this.serverVersion)
          ) {
            this.hotReload();
          }
        }
      }
    });

    fb.auth().onAuthStateChanged(function (user) {
      if (user) {
        //DO NTG
      }
    });
  },
  mounted() {
    this.onResize();
    this.windowSetHeight();
    let containerScroll: any = this.$refs.bodyScroll
      ? this.$refs.bodyScroll
      : null;
    containerScroll?.addEventListener("scroll", (e: any) => {
      this.$root.$emit("body-scroll", containerScroll);
    });

    window.addEventListener("resize", this.onResize, { passive: true });
    window.navigator.onLine ? this.iAmOnline(true) : this.iAmOnline(false);

    window.addEventListener("online", (event) => {
      this.iAmOnline(true);
    });
    window.addEventListener("offline", (event) => {
      this.iAmOnline(false);
    });
    this.$root.$on("showCookies", () => (this.cookiesDialog = true));
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize);
    }
  },
});
