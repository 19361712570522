import Vue from "vue";
import VueRouter from "vue-router";
import { user } from "@/store/user.module";
import store from "../store";
import { IS_STAGING, IS_PROD, IS_DEMO } from "@/config/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "app",
    component: () => import("@/App.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: { hideNavigation: true },
  },
  {
    path: "/home-contactbook",
    name: "home-contactbook",
    component: () => import("@/components/home/HomeContactBook.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home-groups",
    name: "home-groups",
    component: () => import("@/components/home/HomeGroups.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home-messenger",
    name: "home-messenger",
    component: () => import("@/components/home/HomeMessenger.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home-patients",
    name: "home-patients",
    component: () => import("@/components/home/HomePatients.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/patient/:id",
    name: "patient",
    component: () => import("@/views/PatientFolder.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home-notifications",
    name: "home-notifications",
    component: () => import("@/components/home/HomeNotifications.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/:id",
    name: "account",
    component: () => import("@/views/Account.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/group/:id",
    name: "group",
    component: () => import("@/views/Group.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/discussion/:id",
    name: "discussion",
    component: () => import("@/views/Discussion.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/About.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name == "home-group" ||
    to.name == "home-messenger" ||
    to.name == "home-patient"
  ) {
    if (!user.state.userData as any) {
      next({ name: "home-contactbook" });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
