import Vue from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import vuetify from "@/plugins/vuetify";
import VueApexCharts from "vue-apexcharts";
import fb from "@/firebase/init";
import "firebase/auth";
import { Icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
//@ts-ignore
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

// vue formulate
import VueFormulate from "@braid/vue-formulate";
import { fr, en, es, pt } from "@braid/vue-formulate-i18n";
import Calendar from "@/components/global/Calendar.vue";
import DatePicker from "@/components/global/DatePicker.vue";
import AutoComplete from "@/components/global/AutoComplete.vue";
import SignaturePad from "@/components/global/SignaturePad.vue";
import { IS_PROD } from "@/config/index";
import ComboBox from "@/components/global/ComboBox.vue";
import {
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
} from "vuetify/lib";

// Scss
import "@/scss/init.scss";
// import ms from "@/locales/formulate/ms.ts";

//vue formulate
Vue.component("Calendar", Calendar);
Vue.component("DatePicker", DatePicker);
Vue.component("AutoComplete", AutoComplete);
Vue.component("SignaturePad", SignaturePad);
Vue.component("ComboBox", ComboBox);
Vue.component("v-expansion-panels", VExpansionPanels);
Vue.component("v-expansion-panel", VExpansionPanel);
Vue.component("v-expansion-panel-header", VExpansionPanelHeader);
Vue.component("v-expansion-panel-content", VExpansionPanelContent);

//map
Vue.component("l-marker-cluster", Vue2LeafletMarkerCluster);
Vue.component("l-map", LMap);
Vue.component("l-tilelayer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-tooltip", LTooltip);

type D = Icon.Default & {
  _getIconUrl: string;
};

// delete (Icon.Default.prototype as D)._getIconUrl;
// this part resolve an issue where the markers would not appear

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueFormulate, {
  plugins: [pt, fr, es, en],
  library: {
    calendar: {
      component: "Calendar",
      slotProps: {
        component: ["availabilities", "timeZone"],
      },
    },
    datePicker: {
      component: "DatePicker",
      slotProps: {
        component: ["standardFormat"],
      },
    },
    autoComplete: {
      component: "AutoComplete",
      slotProps: {
        component: ["items"],
      },
    },
    comboBox: {
      component: "ComboBox",
      slotProps: {
        component: ["items"],
      },
    },
    signature: {
      component: "SignaturePad",
    },
    VExpansionPanels: {
      component: "v-expansion-panels",
      slotProps: {
        component: ["flat", "accordion"],
      },
    },
    VExpansionPanel: {
      component: "v-expansion-panel",
    },
    VExpansionPanelHeader: {
      component: "v-expansion-panel-header",
    },
    VExpansionPanelContent: {
      component: "v-expansion-panel-content",
    },
  },
});

Vue.config.productionTip = false;
Vue.config.devtools = true;
let app: any = null;

fb.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: (h) => h(App),
      created() {
        if (IS_PROD) {
          window.addEventListener("beforeunload", this.handler);
        } else {
          //Nothing to do
        }
      },
      methods: {
        handler: async function handler(event: any) {
          fb.auth()
            .signOut()
            .then((response) => {});
        },
      },
    });
    app.$mount("#app");
  }
});
