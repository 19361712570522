






import Vue from "vue";
export default Vue.extend({
  name: "MycButton",
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    terciary: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "blue--500",
    },
    textColor: {
      type: String,
      default: "white",
    },
    small: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    let buttonChildren = (this.$refs.buttonContent as any)?.children;
    switch (buttonChildren.length) {
      case 1:
        break;
      case 2:
        buttonChildren[1].classList.add("ml8");
        break;
      case 3:
        buttonChildren[1].classList.add("my8");
        break;
      default:
        break;
    }
  },
  methods: {
    getClass() {
      if (this.primary) {
        if (this.small) {
          return "primary-" + this.color + "-small button-text-color-" + this.textColor;
        } else {
          return "primary-" + this.color + "-regular button-text-color-" + this.textColor;
        }
      } else if (this.secondary) {
        if (this.small) {
          return "secondary-" + this.color + "-small button-text-color-" + this.textColor;
        } else {
          return "secondary-" + this.color + "-regular button-text-color-" + this.textColor;
        }
      } else if (this.terciary) {
        if (this.small) {
          return "terciary-" + this.color + "-small button-text-color-" + this.textColor;
        } else {
          return "terciary-" + this.color + "-regular button-text-color-" + this.textColor;
        }
      }
    },
    eventClick() {
      if (!this.loading) {
        this.$emit("click");
      }
    },
  },
});
