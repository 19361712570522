

















































































































































































































































































import Vue from "vue";
import AvatarPicture from "@/components/global/AvatarPicture.vue";
import { removeChars, normalizeString } from "@/tscript/utils";
import * as _ from "lodash";
import { IS_STAGING } from "@/config/index";
import { mapState, mapActions } from "vuex";
import MycButton from "@/components/global/buttons/MycButton.vue";

export default Vue.extend({
  name: "NavBar",
  props: {
    year: String,
    version: String,
  },
  components: {
    AvatarPicture,
    MycButton,
  },
  data() {
    return {
      collapseOnScroll: true,
      langs: Array(),
      links: Array(),
      showSearch: false,
      itemsFound: new Array(),
      loadingSearchBar: false,
      searchLabel: "",
      search: "",
      select: null,
      isMobile: false,
      patientsList: new Array(),
      quarantinesList: new Array(),
      campaignName: null,
      navLinkId: 0,
      cookiesDialog: false,
      IS_STAGING: IS_STAGING,
      isAnalytics: false, //ANALYTICS_SITE
      menuIsOpen: false,
    };
  },
  computed: {
    ...mapState("user", ["userData"]),
    navindicatorPosition() {
      let vm: any = this;
      if (window.innerHeight < 900) {
        return "top:" + vm.navLinkId * 52 + "px";
      } else {
        return "top:" + vm.navLinkId * 62 + "px";
      }
    },
    windowHeight() {
      return "height: " + window.innerHeight + "px";
    },
  },
  methods: {
    ...mapActions("analytics", ["logAction"]),
    goTo(direction: string) {
      if (window.innerWidth < 600) {
        this.$emit("close-menu");
      }

      this.$router.push({ name: direction }).catch(() => {});
      this.getCurrentIndicatorPosition();
    },
    goLogin() {
      this.$router.push({ name: "login" }).catch(() => {});
    },
    goHome() {
      this.$emit("go-home");
    },
    getHoverIndicatorPosition(target: any) {
      let reference = this.$refs;
      const containerLinks = (reference["container-links"] as any)?.children;
      if (containerLinks) {
        this.navLinkId =
          Array.prototype.indexOf.call(
            (reference["container-links"] as any)?.children,
            reference[target]
          ) - 1;
      }
    },
    getCurrentIndicatorPosition() {
      let reference = this.$refs;
      let routename: any = this.$route.name;
      routename = routename?.replace("home-", "");
      const containerLinks = (reference["container-links"] as any)?.children;
      if (containerLinks) {
        this.navLinkId =
          Array.prototype.indexOf.call(
            (reference["container-links"] as any)?.children,
            reference[routename]
          ) - 1;
      }
    },
    showCookie() {
      this.$emit("showCookies");
    },
    hotReload() {
      this.$emit("hotReload");
    },
    getMenuData() {
      const menuData: any = [
        { icon: "mdi-home", text: this.$i18n.t("navbar.home"), route: "/" },
        {
          icon: "mdi-information",
          text: this.$i18n.t("navbar.about"),
          route: "/about",
        },
      ];
      if (
        this.userData &&
        this.userData.role &&
        (this.userData.role == "ADMIN" || this.userData.role == "CLIENT_ADMIN")
      ) {
        const adminMenu = {
          icon: "mdi-nuke",
          text: this.$i18n.t("navbar.admin"),
          route: "/admin",
        };
        menuData.push(adminMenu);
      }
      return menuData;
    },
    getLanguageData() {
      return [
        { langISO: "fr", text: this.$i18n.t("navbar.french") },
        { langISO: "en", text: this.$i18n.t("navbar.english") },
        { langISO: "ms", text: this.$i18n.t("navbar.malaysian") },
        { langISO: "pt", text: this.$i18n.t("navbar.portuguese") },
        { langISO: "es", text: this.$i18n.t("navbar.spanish") },
      ];
    },
    updateLang(langIdx: any) {
      this.menuIsOpen = false;
      if (this.langs && this.langs.length > 0) {
        const langISO = this.langs[langIdx].langISO;
        this.$i18n.locale = langISO;
        this.$store.commit("setLocale", langISO);
        //Generate the menus
        this.renderMenus();
      }
    },
    logout() {
      this.$store.dispatch("logOutAction").then(() => {
        this.$router.push({ name: "login" });
      });
    },
    renderMenus() {
      this.links = this.getMenuData();
      this.langs = this.getLanguageData();
    },
    goToAccount() {
      this.menuIsOpen = false;
      const id = this.userData?.id ?? "new";
      this.$router
        .push({ name: "account", params: { id: id } })
        .catch((err) => {});
    },
    goToTelemonitoring(id: string) {
      this.$router
        .push({ name: "telemonitoring", params: { id: id } })
        .catch(() => {});
    },
    goToDashboard() {
      this.$router
        .push({ name: "home-dashboard", params: { name: "outpatient" } })
        .catch(() => {});
    },
    querySelections(value: any) {
      this.logAction({ event_name: "search_engine" });
      this.loadingSearchBar = true;
      const searchValue: string = removeChars(value).toLowerCase();

      if (this?.patientsList && this.quarantinesList) {
        const patientsFound = this.patientsList.filter(function (x: any) {
          const xName = removeChars(x?.name).toLowerCase();

          let found = false;
          if (xName) {
            found = xName.includes(searchValue);
          }
          return found;
        });

        const quarantinesFound = this.quarantinesList.filter(function (x: any) {
          const xName = removeChars(x?.name).toLowerCase();

          let found = false;
          if (xName) {
            found = xName.includes(searchValue);
          }
          return found;
        });

        const mergeItems = patientsFound.concat(quarantinesFound);

        this.itemsFound = _.sortBy(mergeItems, (o) => o.name);
      }

      this.loadingSearchBar = false;
    },

    filterAutocomplete(item: any, queryText: string, itemText: string) {
      if (queryText === null || queryText.length === 0) {
        return true;
      }

      const testQuery =
        normalizeString(item["name"]).indexOf(normalizeString(queryText)) > -1;

      return itemText?.length > 0 && testQuery;
    },
    goToPatient(id: string) {
      this.$router
        .push({ name: "patient", params: { id: id } })
        .catch(() => {});
    },
    isNotSignUpView() {
      return !(
        this.userData.client_logo_url &&
        !this.userData.client_name &&
        !this.userData.client_name
      );
    },
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setPatientsList" && state?.patientsList) {
        this.patientsList = state?.patientsList;
      }
    });
  },
  mounted() {
    if (this.$vuetify?.breakpoint?.xsOnly) {
      this.isMobile = true;
    }
    this.langs = this.getLanguageData();
    this.$router.onReady(() => {
      this.getCurrentIndicatorPosition();
    });
  },
  watch: {
    search(value) {
      value && value !== this.select && this.querySelections(value);
    },
    select: function () {
      const selectItem: any = this.select;
      if (selectItem) {
        const quarantine = _.find(this.quarantinesList, ["id", selectItem]);
        const patient = _.find(this.patientsList, ["id", selectItem]);
        if (patient) {
          this.goToPatient(selectItem);
        }
        if (quarantine) {
          this.goToTelemonitoring(selectItem);
        }
      }
    },
  },
});
