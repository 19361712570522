import firebase from "firebase/app";
require("firebase/functions");
require("firebase/storage");
require("firebase/analytics");
require("firebase/performance");

import {FIREBASE_CONFIG} from "@/config/index";

const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
export const perf = firebase.performance();

export default firebaseApp;
