


















import Vue from "vue";
import VueSignaturePad from "vue-signature-pad";
import DBHelper from "@/tscript/dbHelper";
import db from "@/firebase/db";
import { mapState } from "vuex";

Vue.use(VueSignaturePad);

export default Vue.extend({
  name: "SignaturePad",
  data() {
    return {
      dbHelper: new DBHelper(db),
      imageCompressed: "",
    };
  },
  watch: {
    userData() {
      this.loadData();
    },
  },
  methods: {
    /**
     * ASCII to Unicode (decode Base64 to original data)
     * @param {string} b64
     * @return {string}
     */
    atou(b64: any) {
      return decodeURIComponent(escape(atob(b64)));
    },
    /**
     * Unicode to ASCII (encode data to Base64)
     * @param {string} data
     * @return {string}
     */
    utoa(data: any) {
      return btoa(unescape(encodeURIComponent(data)));
    },

    async loadData() {
      if (this.userData?.id) {
        const currentUser = await this.dbHelper.getDocFromCollection(
          "users",
          this.userData.id
        );
        const signaturePad: any = this.$refs.signaturePad;
        if (currentUser?.signature) {
          if (signaturePad?.fromDataURL) {
            signaturePad.fromDataURL(currentUser.signature);
          }
        }
      }
    },

    async clear() {
      (
        this.$refs.signaturePad as Vue & {
          clearSignature: () => void;
        }
      ).clearSignature();
      const currentUser = await this.dbHelper.getDocFromCollection(
        "users",
        this.userData.id
      );
      if (currentUser?.id) {
        currentUser.signature = "";
        const uid = currentUser.id;
        delete currentUser.id;
        await this.$store.state.apiClient
          .postSaveAccountData({ data: currentUser })
          .catch((error: any) => {
            return error;
          });
        currentUser.id = uid;
      }
    },

    onBegin() {},
    async onEnd() {
      const { data } = (
        this.$refs.signaturePad as Vue & {
          saveSignature: () => { isEmpty: any; data: any };
        }
      ).saveSignature();
      const signaturePad: any = this.$refs.signaturePad;
      if (signaturePad?.saveSignature) {
        const { data } = signaturePad.saveSignature("image/svg+xml");
        const currentUser = await this.dbHelper.getDocFromCollection(
          "users",
          this.userData.id
        );
        if (currentUser?.id) {
          currentUser.signature = data;
          const uid = currentUser.id;
          await this.$store.state.apiClient
            .postSaveAccountData({ data: currentUser })
            .catch((error: any) => {
              return error;
            });
          currentUser.id = uid;
        }
      }
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
  },
  created() {
    this.loadData();
  },
});
