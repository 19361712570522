







import Vue from "vue";

export default Vue.extend({
  name: "AutoComplete",
  props: {
    items: {
      type: Array,
      required: true,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasObject: false,
      itemText: "",
      itemValue: "",
    };
  },
  methods: {},
  created() {
    const hasObject = this.items?.length ? typeof this.items[0] == "object" : false;
    if (hasObject) {
      this.itemText = "text";
      this.itemValue = "value";
    }
  },
});
