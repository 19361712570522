







import Vue from "vue";

export default Vue.extend({
  name: "comboBox",
  props: {
    items: {
      type: Array,
      required: true,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  watch: {
    value() {
      // eslint-disable-next-line vue/no-mutating-props
      this.context.model = this.value;
    },
  },
});
