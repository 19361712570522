<template>
  <transition appear :name="transitionName">
    <div class="Cookie" :class="[containerPosition, cookieTheme]" v-if="isOpen">
      <slot :accept="accept" :close="close" :decline="decline" :open="open">
        <div class="Cookie__content">
          <slot name="message">{{ message }}</slot>
        </div>
        <div class="Cookie__buttons">
          <a :target="target" :href="buttonLink" v-if="externalButtonLink" :class="buttonClass">{{ buttonLinkText }}</a>
          <router-link :to="buttonLink" v-if="internalButtonLink" :class="buttonClass">{{ buttonLinkText }}</router-link>
          <button v-if="buttonDecline" :class="buttonDeclineClass" @click="decline">
            {{ buttonDeclineText }}
          </button>
          <button :class="buttonClass" @click="accept">{{ buttonText }}</button>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
import * as Cookie from "tiny-cookie";
import CookieHelper from "@/tscript/cookieHelper";

const STORAGE_TYPES = {
  local: "localStorage",
  cookies: "cookies",
};

export default {
  name: "VueCookieLaw",
  props: {
    buttonText: {
      type: String,
      default: "Got it!",
    },
    buttonDecline: {
      type: Boolean,
      default: false,
    },
    buttonDeclineText: {
      type: String,
      default: "Decline",
    },
    buttonLink: {
      type: [String, Object],
      required: false,
    },
    buttonLinkText: {
      type: String,
      default: "More info",
    },
    buttonLinkNewTab: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "This website uses cookies to ensure you get the best experience on our website.",
    },
    theme: {
      type: String,
      default: "base",
    },
    /**
     * Cookie Container position
     * bottom, top
     * @type {Object}
     */
    position: {
      type: String,
      default: "bottom",
    },
    /**
     * Transition name has following possibilities
     * slideFromBottom
     * slideFromTop
     * fade
     * @type {Object}
     */
    transitionName: {
      type: String,
      default: "slideFromBottom",
    },
    buttonClass: {
      type: String,
      default: "Cookie__button",
    },
    buttonDeclineClass: {
      type: String,
      default: "Cookie__button--decline",
    },
    storageName: {
      type: String,
      default: "cookies:consent",
    },
    storageType: {
      type: String,
      default: STORAGE_TYPES.local,
    },
    cookieOptions: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      cookieHelper: new CookieHelper(),
      isOpen: false,
    };
  },
  computed: {
    containerPosition() {
      return `Cookie--${this.position}`;
    },
    cookieTheme() {
      return `Cookie--${this.theme}`;
    },
    externalButtonLink() {
      return typeof this.buttonLink === "string" && this.buttonLink.length;
    },
    internalButtonLink() {
      return typeof this.buttonLink === "object" && this.buttonLink != null && Object.keys(this.buttonLink).length;
    },
    target() {
      return this.buttonLinkNewTab ? "_blank" : "_self";
    },
  },
  created() {
    if (!this.cookieHelper.getVisited()) {
      this.isOpen = true;
    }
  },
  mounted() {
    if (this.cookieHelper.isAccepted()) {
      this.$emit("accept");
    }
  },
  methods: {
    accept(cookiesDetails) {
      if (!cookiesDetails) cookiesDetails = {};

      const cookiesDetailString = JSON.stringify(cookiesDetails);
      this.cookieHelper.setVisited(cookiesDetailString);
      this.cookieHelper.setAccepted(cookiesDetailString);
      this.isOpen = false;
      this.$emit("accept");
    },
    close() {
      this.isOpen = false;
      this.$emit("close");
    },
    decline() {
      this.cookieHelper.setVisited();
      this.cookieHelper.setDeclined();
      this.isOpen = false;
      this.$emit("decline");
    },
    revoke() {
      if (this.cookieHelper.canUseLocalStorage) {
        localStorage.removeItem(this.storageName);
      } else {
        Cookie.remove(this.storageName);
      }
      this.isOpen = true;
      this.$emit("revoke");
    },
    open() {
      if (!this.cookieHelper.getVisited()) {
        this.isOpen = true;
      }
    },
  },
};
</script>
