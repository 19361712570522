



















































import Vue from "vue";
import i18n from "@/i18n";

export default Vue.extend({
  name: "Calendar",
  props: {
    availabilities: {
      type: Array,
      required: true,
    },
    timeZone: {
      type: String,
      required: true,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      calendarIterator: 0,
      calendarNbColumnsDisplayed: 4,
    };
  },
  methods: {
    changeValue(val: any) {
      // eslint-disable-next-line vue/no-mutating-props
      this.context.model = val;
    },
    getHeader() {
      let headers = Object.keys(this.model).slice(this.calendarIterator * this.calendarNbColumnsDisplayed, (this.calendarIterator + 1) * this.calendarNbColumnsDisplayed);

      return headers;
    },
    hasLeft() {
      return this.calendarIterator > 0;
    },
    hasRight() {
      return (this.calendarIterator + 1) * this.calendarNbColumnsDisplayed < Object.keys(this.model).length;
    },
    changeSlots(right: any) {
      if (right) {
        this.calendarIterator++;
      } else {
        this.calendarIterator--;
      }
    },
    structDates() {
      const values: any = Object.values(this.model);
      const restructuredDates: any = [];
      const columnsDisplayed = values.slice(this.calendarIterator * this.calendarNbColumnsDisplayed, (this.calendarIterator + 1) * this.calendarNbColumnsDisplayed);
      const nbRows: any = columnsDisplayed.reduce(function (accumulateur: any, valeurCourante: any) {
        if (accumulateur > valeurCourante.length) return accumulateur;
        else return valeurCourante.length;
      }, 0);
      for (let i = 0; i < nbRows; i++) {
        restructuredDates.push([]);
      }
      for (let j = 0; j < nbRows; j++) {
        const maxColumns =
          (this.calendarIterator + 1) * this.calendarNbColumnsDisplayed > Object.keys(values).length ? Object.keys(values).length : (this.calendarIterator + 1) * this.calendarNbColumnsDisplayed;
        for (let i = this.calendarIterator * this.calendarNbColumnsDisplayed; i < maxColumns; i++) {
          restructuredDates[j].push(values[i][j] && values[i][j].id != "none" ? values[i][j] : {hour: "", start: i, id: j});
        }
      }
      for (const line of restructuredDates) {
        let i = 0;
        while (line.length != this.calendarNbColumnsDisplayed) {
          line.push({hour: "", start: "none", id: line[0].start + "_" + i});
          i++;
        }
      }
      return restructuredDates;
    },
    structAvailabilities(campaignAvailabilities: any, localLanguage: any, timeZone: any, onFiveOpenDays: boolean = false) {
      const weekDisplay = 5;

      const availabilities = {};
      for (const a of campaignAvailabilities) {
        if (!onFiveOpenDays || Object.keys(availabilities).length < weekDisplay) {
          const options = {
            weekday: "short",
            month: "long",
            day: "numeric",
            timeZone: timeZone,
          };
          const dateF = new Date(a.start);
          //@ts-ignore
          const day = dateF.toLocaleDateString(localLanguage, options);
          const s = new Date(a.start).toLocaleString("en-US", {
            timeZone: timeZone,
          });
          const splitter = s.split(" ");
          const splitter2 = splitter[1].split(":");
          const hour = splitter2[0] + ":" + splitter2[1] + " " + splitter[2];

          const value = {
            hour: hour,
            id: a.resources[0]?.id,
            start: a.start,
            end: a.end,
          };

          if ((availabilities as any)[day]) {
            (availabilities as any)[day].push(value);
          } else {
            (availabilities as any)[day] = [value];
          }
        }
      }
      return availabilities;
    },
    loadData() {
      this.model = this.structAvailabilities(this.availabilities, this.$i18n.locale, this.timeZone);

      const daysToAdd = Object.keys(this.model).length % this.calendarNbColumnsDisplayed;

      const nextDay = new Date((this.availabilities[this.availabilities.length - 1] as any).start);

      for (let i = 0; i < daysToAdd; i++) {
        //TODO has to be change with display the five days see notes
        //if (nextDay.getDay() == 5) nextDay.setDate(nextDay.getDate() + 3);
        //else nextDay.setDate(nextDay.getDate() + 1);

        nextDay.setDate(nextDay.getDate() + 1);

        const options = {
          weekday: "short",
          month: "long",
          day: "numeric",
          timeZone: this.timeZone,
        };

        const nextDayFormatted = nextDay.toLocaleDateString(
          this.$i18n.locale,
          //@ts-ignore
          options,
        );

        (this.model as any)[nextDayFormatted] = [{id: "none"}];
      }
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    if (window.innerWidth < 600 && window.innerWidth > 330) {
      this.calendarNbColumnsDisplayed = 3;
    } else if (window.innerWidth < 330) {
      this.calendarNbColumnsDisplayed = 2;
    }
  },
});
